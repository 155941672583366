<template>
  <div>
    <v-container v-if="user">
      <v-row no-gutters class="mb-5">
        <v-col cols="12" class="d-flex flex-column justify-start">
          <h4 class="font-weight-bold default--text">
            {{ $t("dashboard.title", [user.firstName]) }}
          </h4>
          <span class="text-caption" v-html="$t('dashboard.subtitle')"> </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="pa-3">
          <DashboardCard
            :title="$t('dashboard.profile')"
            icon="$profile"
            routePath="/profile/profile-update"
          />
        </v-col>
        <v-col cols="6" class="pa-3">
          <DashboardCard
            :title="$t('dashboard.orders')"
            icon="$orders"
            routePath="/profile/orders"
          />
        </v-col>
        <v-col cols="6" class="pa-3">
          <DashboardCard
            :title="$t('dashboard.lists')"
            icon="$heart"
            routePath="/profile/lists"
          />
        </v-col>
        <v-col cols="6" class="pa-3">
          <DashboardCard
            :title="$t('dashboard.favorite')"
            icon="$like"
            routePath="/profile/preferred"
          />
        </v-col>
        <v-col cols="6" class="pa-3">
          <DashboardCard
            :title="$t('dashboard.gifts')"
            icon="$tag"
            routePath="/profile/gifts"
          />
        </v-col>
        <v-col cols="6" class="pa-3" @click="doLogout">
          <DashboardCard :title="$t('dashboard.logout')" icon="$logout" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import RegistrationService from "~/service/userService";
import DashboardCard from "@/components/profile/DashboardCard";
import { mapActions } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      user: null
    };
  },
  created() {
    this.fetchUserData();
  },
  components: {
    DashboardCard
  },
  methods: {
    fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
      });
    },
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>

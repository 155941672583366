<template>
  <v-card
    :to="routePath"
    class="d-flex flex-column justify-center align-center pt-5 pb-4"
  >
    <v-icon color="primary" large class="mb-5">{{ icon }}</v-icon>
    <v-card-title
      class=" pa-0 text-body-2 font-weight-bold default--text text-center"
    >
      {{ title }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    routePath: { type: String, required: false }
  }
};
</script>
